import { Injectable } from '@angular/core';
import { SettingsService } from './settings.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ThingService {

  apiBaseUrl: string;

  modelName = 'Things';

  updatedThings = [];

  public returningFromThingEditor = false;

  constructor(
    private http: HttpClient,
    private settings: SettingsService
  ) {
    this.apiBaseUrl = this.settings.apiBaseUrl;
  }

  get(filter: any = {}, shared: boolean = false) {
    return new Promise(resolve => {

      const accountId = 0;
      
      const qp = {
        filter: JSON.stringify(filter)
      };

      let url = this.apiBaseUrl + 'Accounts/' + accountId + '/' + this.modelName;

      if (shared) {
        url = this.apiBaseUrl + this.modelName + '/getShared';
      }

      this.http.get(url, {params: qp}).subscribe((data: any) => {
        console.log('Got data from API: ', data);
        resolve(data);
      }, httpError => {
        resolve(httpError.error);
      });

    });
  }

  getById(id: number, filter: any = {}, shared: boolean = false) {
    return new Promise(resolve => {

      const accountId = 0;

      const qp = {
        filter: JSON.stringify(filter)
      };
    
      let url = this.apiBaseUrl + 'Accounts/' + accountId + '/' + this.modelName + '/' + id;

      if (shared) {
        url = this.apiBaseUrl + this.modelName + '/getByIdShared/' + id;
      }

      this.http.get(url, { params: qp }).subscribe((data: any) => {
        console.log('Got data from API: ', data);
        resolve(data);
      }, httpError => {
        resolve(httpError.error);
      });

    });
  }

  getThingDisplayFields(thing: any) {

    const displayFields = {
      name: '',
      description: '',
      address: '',
      phone: '',
      email: '',
      website: '',
      number: '',
      float: '',
      dateTime: '',
      checkbox: '',
      select: '',
      rating: '',
      imageUrl: '',
      fileUrl: '',
      barcodeScan: '',
      googleMap: '',
      youTubeVideo: '',
      remoteApiCall: ''
    };

    for (let thingField of thing.thingFields) {

      if (thingField.collectionField.fieldTypeId === 1 && !displayFields.name && thingField.fieldValueStrings[0].value !== null) {
        displayFields.name = thingField.fieldValueStrings[0].value;
      }

      if (thingField.collectionField.fieldTypeId === 2 && !displayFields.description && thingField.fieldValueTexts[0].value !== null) {
        displayFields.description = thingField.fieldValueTexts[0].value;
      }

      if (thingField.collectionField.fieldTypeId === 3 && !displayFields.address && thingField.fieldValueAddresses[0].value !== null) {
        displayFields.address = thingField.fieldValueAddresses[0].value;
      }

      if (thingField.collectionField.fieldTypeId === 4 && !displayFields.phone && thingField.fieldValuePhones[0].value !== null) {
        displayFields.phone = thingField.fieldValuePhones[0].value;
      }

      if (thingField.collectionField.fieldTypeId === 5 && !displayFields.email && thingField.fieldValueEmails[0].value !== null) {
        displayFields.email = thingField.fieldValueEmails[0].value;
      }

      if (thingField.collectionField.fieldTypeId === 6 && !displayFields.website && thingField.fieldValueWebsites[0].value !== null) {
        displayFields.website = thingField.fieldValueWebsites[0].value;
      }

      if (thingField.collectionField.fieldTypeId === 7 && !displayFields.number && thingField.fieldValueNumbers[0].value !== null) {
        displayFields.number = thingField.collectionField.name + ': ' + thingField.fieldValueNumbers[0].value.toString();
      }

      if (thingField.collectionField.fieldTypeId === 8 && !displayFields.float && thingField.fieldValueFloats[0].value !== null) {
        displayFields.float = thingField.collectionField.name + ': ' + thingField.fieldValueFloats[0].value.toString();
      }

      if (thingField.collectionField.fieldTypeId === 9 && !displayFields.dateTime && thingField.fieldValueDateTimes[0].value !== null) {
        displayFields.dateTime = thingField.collectionField.name + ': ' + thingField.fieldValueDateTimes[0].value;
      }

      if (thingField.collectionField.fieldTypeId === 10 && !displayFields.checkbox && thingField.fieldValueBooleans[0].value !== null) {
        displayFields.checkbox = thingField.collectionField.name + ': ' + thingField.fieldValueBooleans[0].value;
      }

      if (thingField.collectionField.fieldTypeId === 11 && !displayFields.select && thingField.fieldValueSelects[0].value !== null) {
        displayFields.select = thingField.collectionField.name + ': ' + thingField.fieldValueSelects[0].value;
      }

      if (thingField.collectionField.fieldTypeId === 12 && !displayFields.rating && thingField.fieldValueRatings[0].value !== null) {
        displayFields.rating = thingField.fieldValueRatings[0].value;
      }

      if (thingField.collectionField.fieldTypeId === 13 && !displayFields.imageUrl && thingField.fieldValueImages[0].value !== null) {
        displayFields.imageUrl = thingField.fieldValueImages[0].value;
      }

      if (thingField.collectionField.fieldTypeId === 14 && !displayFields.fileUrl && thingField.fieldValueFiles[0].value !== null) {
        displayFields.fileUrl = thingField.fieldValueFiles[0].value;
      }

      if (thingField.collectionField.fieldTypeId === 15 && !displayFields.barcodeScan && thingField.fieldValueBarcodeScans[0].value !== null) {
        displayFields.barcodeScan = thingField.fieldValueBarcodeScans[0].value;
      }

      if (thingField.collectionField.fieldTypeId === 16 && !displayFields.googleMap && thingField.fieldValueLocations[0].value !== null) {
        displayFields.googleMap = thingField.fieldValueLocations[0].value;
      }

      if (thingField.collectionField.fieldTypeId === 17 && !displayFields.youTubeVideo && thingField.fieldValueYouTubeVideos[0].value !== null) {

        if (thingField.fieldValueYouTubeVideos[0].value.indexOf('src="https://www.youtube.com/embed/') !== -1) {
          let youTubeVideoId = this.getYouTubeId(thingField.fieldValueYouTubeVideos[0].value);
          if (youTubeVideoId) {}
          displayFields.youTubeVideo = 'https://img.youtube.com/vi/' + youTubeVideoId + '/default.jpg';
        }

        if (thingField.fieldValueYouTubeVideos[0].value.indexOf('src="https://www.tiktok.com/embed.js') !== -1) {
          displayFields.youTubeVideo = '/assets/images/logos/tiktok-logo.png';
        }

        if (thingField.fieldValueYouTubeVideos[0].value.indexOf('fbcdn.net') !== -1) {
          const imageTag = thingField.fieldValueYouTubeVideos[0].value;
          const endQuoteIndex = imageTag.indexOf('"', 10);
          const imageUrl = imageTag.substring(10, endQuoteIndex);
          displayFields.youTubeVideo = imageUrl;
        }
        
      }

      if (thingField.collectionField.fieldTypeId === 18 && !displayFields.remoteApiCall && thingField.fieldValueRemoteApiCalls[0].value !== null) {
        displayFields.remoteApiCall = thingField.fieldValueRemoteApiCalls[0].value;
      }

    }

    return displayFields;
  }

  getYouTubeId(url: string) {
    const arr = url.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    return undefined !== arr[2] ? arr[2].split(/[^\w-]/i)[0] : arr[0];
  }

}
