import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ThingService } from 'src/app/services/thing.service';
import { Meta } from '@angular/platform-browser';
import Hashids from 'hashids'

// declare let Hashids: any;

@Component({
  selector: 'app-collection-viewer',
  templateUrl: './collection-viewer.component.html',
  styleUrls: ['./collection-viewer.component.scss']
})
export class CollectionViewerComponent implements OnInit {

  collectionId;
  collection;
  isShared;
  encodedCollectionId;
  hashids;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private thingService: ThingService,
    private meta: Meta
  ) { }

  ngOnInit(): void {
    this.isShared = parseInt(this.route.snapshot.paramMap.get('isShared'), 10);
    if (this.isShared) {
      console.log('COLLECTION: is shared');
      this.encodedCollectionId = this.route.snapshot.paramMap.get('collectionId');
      this.hashids = new Hashids('Organize everything with Lyztin', 16);
      this.collectionId = this.hashids.decode(this.encodedCollectionId);
    } else {
      console.log('COLLECTION: is not shared');
      this.collectionId = parseInt(this.route.snapshot.paramMap.get('collectionId'), 10);
    }
    if (!this.collectionId) {
      this.router.navigateByUrl('/home');
    } else {
      this.loadCollection();
    }
  }

  loadCollection() {
    this.thingService.getById(this.collectionId, null, this.isShared).then((collection: any) => {
      if (!collection) {
        this.router.navigateByUrl('/home');
      } else {
        console.log('THING: got collection', collection);
        this.collection = collection;

        const title = collection.name;
        const description = collection.description;
        const image = collection.imageUrl? collection.imageUrl : 'https://lyzt.in/assets/images/logos/logo-210.png';
        const url = 'https://lyzt.in/collection-viewer/' + this.encodedCollectionId + '/1';

        // Add meta tags to page
        this.meta.updateTag({ name: 'url', content: url });
        this.meta.updateTag({ name: 'canonical', content: url });
        this.meta.updateTag({ name: 'title', content: title });
        this.meta.updateTag({ name: 'image', content: image });
        this.meta.updateTag({ name: 'author', content: '' });
        this.meta.updateTag({ name: 'description', content: description });
        this.meta.updateTag({ name: 'keywords', content: '' });
        this.meta.updateTag({ name: 'source', content: 'lyzt.in' });
        this.meta.updateTag({ name: 'price', content: '' });
        this.meta.updateTag({ name: 'priceCurrency', content: '' });
        this.meta.updateTag({ name: 'availability', content: '' });
        this.meta.updateTag({ name: 'robots', content: '' });
        this.meta.updateTag({ name: 'og:url', content: url });
        this.meta.updateTag({ name: 'og:locale', content: '' });
        this.meta.updateTag({ name: 'og:locale:alternate', content: '' });
        this.meta.updateTag({ name: 'og:title', content: title });
        this.meta.updateTag({ name: 'og:type', content: '' });
        this.meta.updateTag({ name: 'og:description', content: description });
        this.meta.updateTag({ name: 'og:determiner', content: '' });
        this.meta.updateTag({ name: 'og:site_name', content: 'Lyztin' });
        this.meta.updateTag({ name: 'og:image', content: image });
        this.meta.updateTag({ name: 'og:image:secure_url', content: image });
        this.meta.updateTag({ name: 'og:image:type', content: 'website' });
        this.meta.updateTag({ name: 'og:image:width', content: '' });
        this.meta.updateTag({ name: 'og:image:height', content: '' });
        this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
        this.meta.updateTag({ name: 'twitter:title', content: title });
        this.meta.updateTag({ name: 'twitter:description', content: description });
        this.meta.updateTag({ name: 'twitter:url', content: url });
        this.meta.updateTag({ name: 'twitter:image', content: image });
        this.meta.updateTag({ name: 'twitter:image:alt', content: title });
        this.meta.updateTag({ name: 'twitter:site', content: 'LyztinApp' });
        this.meta.updateTag({ name: 'twitter:site:id', content: '' });
        this.meta.updateTag({ name: 'twitter:account_id', content: '' });
        this.meta.updateTag({ name: 'twitter:creator', content: '' });
        this.meta.updateTag({ name: 'twitter:creator:id', content: '' });
        this.meta.updateTag({ name: 'twitter:player', content: '' });
        this.meta.updateTag({ name: 'twitter:player:width', content: '' });
        this.meta.updateTag({ name: 'twitter:player:height', content: '' });
        this.meta.updateTag({ name: 'twitter:player:stream', content: '' });
      }
    });
  }

}
