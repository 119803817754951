<div class="footer-container-full" style="box-shadow: 0 50vh 0 50vh #232832;">
    <div class="footer-layout d-flex">
        <div class="offset"></div>
        <div class="grid-block">
            <div class="text-block">
                <span class="text-entry" style="font-size: 18px;">CONTACT US</span><br>
                <span class="text-entry"> <a class="support-link" href="mailto:support@unifiedglobal.com">support@unifiedglobal.com</a> </span><br>
            </div>
        </div>
        <div class="grid-block">
            <div class="text-block">
                <span class="text-entry"> <a class="support-link" [routerLink]="['/terms-of-use']">Terms of Use</a> </span><br>
                <span class="text-entry"> <a class="support-link" [routerLink]="['/privacy-policy']">Privacy Policy</a> </span><br>
            </div>
        </div>
    </div>
</div>