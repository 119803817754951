<div class="container jumbotron">
  <div>
    <h1>Terms of Use</h1>
  </div>

  <div>
    
    <p>(Last Updated: September 2, 2022)</p>

    <p>These Terms of Use, together with the documents referenced herein (collectively the "Terms"), apply to the mobile application named "Lyztin" (the "App"). Unified Global PC Global Services, Inc. ("Unified Global," "we," "us," or "our") permits you to use the App subject to these Terms and any applicable rules applied by Apple Inc. ("App Store Rules") and Google Inc. ("Play Store Rules") with respect to the use of their App Store and Play Store respectively located at&nbsp;<a href="https://www.apple.com/itunes">https://www.apple.com/itunes</a>&nbsp;("App Store") and&nbsp;<a href="https://play.google.com/store">https://play.google.com/store</a>&nbsp;("Play Store"). You ("you," the App user) may only utilize the App in accordance with these Terms.</p>
    <ol>
      <li>
        <b>IMPORTANT NOTICES:&nbsp; &nbsp;&nbsp;</b><p>1.1 Please read these Terms carefully before downloading, installing or using the App. By downloading, installing, accessing, browsing or using the App, you accept and agree to these Terms which bind you legally. The Terms are entered into for the benefit of Unified Global and its affiliated companies ("the Unified Global Companies") who may each enforce against you. If you do not agree to these Terms you are not permitted to use the App and you must immediately cease downloading, installing or using the App.</p>
        <p>1.2 The content provided in the App is intended for general informational purposes only. Companies and individuals should not rely on the information provided in the App for the prevention or mitigation of risks or as an explanation of coverage or benefits under an insurance policy. If you are a Customer of the Unified Global Companies, you may be subject to other agreements with Unified Global. These Terms apply to use of the App and do not change or alter any other contract or agreement between you and the Unified Global Companies. Please refer to your policy documentation or contact your broker or Unified Global customer service representative for more information regarding any applicable Unified Global service or product.</p>
        <p>1.3 If you experience any technical issues with the App, or you would like to contact us regarding these Terms, you may contact Unified Global by sending an email to <a href="mailto:Digital@Unified Global.com">Digital@Unified Global.com</a>.</p>
        <p>1.4 In order to use the App, you must have a compatible device which meets the following minimum specifications: for Apple devices, requires iOS 6.0 or later, and is compatible with iPhone, iPad, and iPod Touch; for Android, 4.4 and up, with both accelerometer and gyroscope sensors. Any compatible device to which you download the App will be known as a "Device" for the purposes of these Terms. You confirm that you either own the applicable Device or if not owned by you, you have obtained permission to install and use the App on such Device. You accept responsibility for any use of the App on or in relation to such Device, whether or not such Device is owned by you or such use was made by you.</p>
        <p>1.5 In order to access the App, you must have a Lyztin account, your accompanying username and password that you, the member, have created, and a four-digit pin code which will be sent to the e-mail address you have provided in order to register.</p>
      </li>
      <li>
        <b>ACKNOWLEDGEMENTS&nbsp;</b><p>2.1 These Terms apply to the App, including any updates or supplements thereto. Unified Global may change or revise these Terms from time to time in its sole discretion, with or without notice to you. You are bound by any such revisions and should therefore periodically visit this page to review the then-current terms and conditions. Your access and use will be subject to the most current version of the Terms. Your use of the App after such revised Terms are made available will signify your acceptance of such revised Terms and your agreement to be bound by them.</p>
        <p>2.2 From time to time, updates to the App may be made available through the App Store and Play Store. Depending on the update, and whether you utilized the App Store or Play Store to download and access the App, you may not be able to use the App until you have installed the latest version.</p>
        <p>2.3 You confirm that you are of legal age in your country to form a binding contract and hereby agree to be bound by these Terms. If you are an employee or other representative of a business or other entity and you are using the App in your representative capacity, then you hereby represent that you are authorised to use the App as a representative of the entity and agree to these Terms on behalf such entity.</p>
      </li>
      <li>
        <b>PRIVACY&nbsp;</b><p>3.1 Your submission of information, including personal information, through or in connection with the App (if any) is governed by the terms of our privacy policy as updated from time to time, available at&nbsp;<a>https://www.Unified Global.com/privacy-policy</a>&nbsp;("Privacy Policy").</p>
      </li>
      <li>
        <b>THIRD PARTY CONTENT&nbsp;</b><p>4.1 The App may contain links to independent third party websites or other applications ("Third Party Sites"). Third Party Sites are not under our control, and you agree that we are not responsible for and do not endorse or monitor their content or privacy policies (if any). We expressly disclaim any liability for loss or damage sustained by you as a result of the accessing and use of Third Party Sites.</p>
      </li>
      <li>
        <b>LICENSE&nbsp;</b><p>5.1 Subject to these Terms, the Privacy Policy and the App Store Rules and Play Store Rules (as applicable), we grant to you a non-exclusive, non-transferable, non-sublicensable, personal, limited, revocable license to use the App on the Devices for the purposes of helping users better understand and improve their driving behaviors. Any other use of the App is strictly prohibited. We reserve all rights in and to the App. Unified Global reserves the right to revoke this license at any time, in its sole discretion.</p>
      </li>
      <li>
        <b>LICENSE RESTRICTIONS&nbsp;</b><p>6.1 Except as expressly permitted under these Terms, and unless prohibited by applicable law, you agree:&nbsp;<br>
        </p>
        <p>(a) not to copy, reproduce, republish, reuse, upload, post, transmit or distribute any content presented in or provided by the App, including without limitation for public or commercial purposes, including any text, images, audio and video, except as permitted by the App's sharing function;&nbsp;</p>
        <p>(b) not to rent, lease, sub-license, loan, distribute, time-share, or translate the App in any way;&nbsp;</p>
        <p>(c) not to make alterations to, or modifications of, the whole or any part of the App, or permit the App or any part of it to be combined with, or become incorporated in, any other programs;</p>
        <p>(d) not to disassemble, decompile, reverse-engineer or create derivative works based on the whole or any part of the App;&nbsp;</p>
        <p>(e) not to sell, resell, or exploit the App in whole or in part (including object and source code), in any form to any person without prior written consent from us;&nbsp;</p>
        <p>(f) not to remove any copyright, trademark or other proprietary rights notices from the App;&nbsp;</p>
        <p>(g) that you represent and warrant that you are not located in a country that is subject to a U.S Government embargo, or that has been designated by the U.S. Government as a "terrorist supporting" country; and that you are not listed on any U.S. Government list of prohibited or restricted parties.</p>
      </li>
      <li>
        <b>ADDITIONAL RESTRICTIONS&nbsp;</b><p>7.1 You agree:&nbsp;<br>
        </p>
        <p>(a) not to use the App in any unlawful manner, for any unlawful or criminal purpose, or in any manner inconsistent with these Terms, or act fraudulently or maliciously, for example, by hacking into or inserting malicious code, including viruses, or harmful data, into the App;&nbsp;</p>
        <p>(b) not to use the App to record, post or transmit any information that is defamatory, threatening, abusive, offensive, obscene, indecent, discriminatory or is otherwise objectionable or infringes on third party rights;&nbsp;</p>
        <p>(c) not to use the App in a way that could damage, disable, overburden, impair or compromise our systems or security or interfere with other users, or restrict or inhibit any other users from using the App (including by hacking or defacing the App); and&nbsp;</p>
        <p>(d) not to collect or harvest any information or data from the App or our systems or attempt to decipher any transmissions to or from the servers running the App, including, without limitation, by using any robot, spider, site search/retrieval application or other manual or automatic device to retrieve, index, "scrape," "data mine" or in any way gather the App content or reproduce or circumvent the navigational structure or presentation of the App without our prior written consent.</p>
      </li>
      <li>
        <b>INTELLECTUAL PROPERTY RIGHTS&nbsp;</b><br>
        <p>8.1&nbsp;&nbsp;acknowledge that (i) all intellectual property rights in and to the App belong to the Unified Global Companies or their licensors, (ii) the rights granted herein are licensed (not sold) to you, and (iii) you have no rights in, or to, the App or its content other than the right to use the App in accordance with these Terms.&nbsp;</p>
        <p>8.2 In the event that you elect to share information with the Unified Global Companies through the App's "Email Us" function, you agree and acknowledge that (i) all information supplied by you is either original to you, or you have the right to share it with the Unified Global Companies, (ii) the Unified Global Companies may utilize the information you supply, in whole or in part, in any manner in connection with the App, (iii) any modifications or improvements made to the App or Unified Global's products or services as a result of your feedback are owned and controlled solely by the Unified Global Companies, (iv) you have no right, title or interest in or to the App as a result of sharing your feedback. Do not submit any confidential, proprietary or personal information through the "Email Us" function.</p>
        <p>8.3 All trademarks, service marks, trade names, and logos are proprietary to the Unified Global Companies or used by Unified Global with the permission of its third party providers. Nothing contained on in this App should be construed as granting, by implication, estoppel, or otherwise, any license or right to use any trademark displayed on this website without the written permission of Unified Global. Your use of the trademarks displayed in this App, or any other content in this App, except as provided herein, is strictly prohibited. You acknowledge that you have no right to have access to the App in source code form.</p>
      </li>
      <li>
        <b>DISCLAIMER&nbsp;</b><p>9.1 The App is provided 'as is' and, to the extent permitted by applicable law, Unified Global and its' directors, officers, employees, agents, representatives, licensors, third party providers and affiliates, exclude all representations or warranties of any kind, express or implied including, but not limited to, implied warranties of merchantability, satisfactory quality, fitness for a particular purpose and non-infringement. Any reliance on or use of the App shall be at your sole risk. The Unified Global Companies, the App Store and the Play Store, shall not have any obligation to furnish any maintenance and/or support services with respect to the App. We expressly disclaim any liability for loss or damage sustained by you as a result of the accessing and use of the App.</p>
        <p>9.2 We make no representation or warranty as to the completeness, accuracy or currentness of information or content provided in the App. Such information is also subject to change at any time without notice.</p>
        <p>9.3 We do not warrant that the functions contained in the App will be uninterrupted or error-free, that defects will be corrected or that the App is free of viruses or other harmful components. We do not warrant or make any representations regarding the use or the results of the use of the App. You agree to conduct your own due diligence to assess the accuracy, reliability and quality of all content provided by the App.</p>
        <p>9.4 You (and not us) assume the entire cost of all necessary servicing, repair or correction in respect of your Device.</p>
        <p>9.5 Unified Global is the marketing name for the worldwide property-casualty, life and retirement, and general insurance operations of American International Group, Inc. Insurance, products and services are written or provided by subsidiaries or affiliates of American International Group, Inc. Please refer to the actual policy or the relevant product or services agreement. For additional information, please visit our website at&nbsp;<a>https://www.Unified Global.com</a>. Not all products and services are available in every jurisdiction, and insurance coverage is governed by actual policy language. Certain products and services may be provided by independent third parties.</p>
      </li>
      <li>
        <b>LIMITED WARRANTY&nbsp;</b><p>10.1 The App shall be validly licensed, of satisfactory quality, fit for the purpose for which it was provided and shall comply in all material respects with the description made available to you prior to downloading or installing it. In the event of any failure to comply with the warranty given in this section 10.1 Unified Global shall, as your sole remedy, either repair or replace the App or if that is not possible provide an appropriate refund in respect of the affected feature or function.</p>
        <p>10.2 If through our failure to exercise reasonable skill and care we provide you with digital content which damages a Device, you may be entitled to compensation under consumer law. However we will not be liable for damage that you could have avoided by installing the latest version of the App or for damage that was caused by you failing to correctly install the App or have in place the minimum system requirements.</p>
      </li>
      <li>
        <b>AVAILABILITY&nbsp;</b><p>11.1 We reserve the right, temporarily or permanently, in whole or in part, to modify, suspend or discontinue the App without notice for any reason without liability to you, except where prohibited by applicable law.</p>
        <p>11.2 In addition, you acknowledge that the App is provided over the Internet and mobile networks and so the quality and availability of the App may be affected by factors outside our reasonable control. Accordingly, we do not accept any responsibility for any connectivity issues that you may experience when using the App. In addition, you acknowledge and agree that you (and not us) are responsible for obtaining and maintaining all telecommunications, broadband and computer hardware, equipment and services needed to access and use the App, and for paying any data charges that may be charged to you by your network provider in connection with your use of the App. In the event you choose to share information from the App by utilizing SMS/text functionality, you acknowledge and agree that you (and not us) are responsible for paying any related fees that may be charged to you by your network or telecommunications provider.</p>
      </li>
      <li>
        <b>LIMITATION OF LIABILITY&nbsp;</b><p>12.1 You agree that you use the App and/or any Third Party Sites at your own risk. You further understand and agree that we are not responsible or liable for your illegal, unauthorized, or improper use of information transmitted, monitored, stored or received using the App.</p>
        <p>12.2 To the fullest extent permitted under applicable law, in no event shall we be liable to you personally for any loss, injury or damages (including but not limited to any special, indirect, consequential or punitive damages, lost profits, lost revenues or other incidental damages) arising out of the use, inability to use, or the results of use of the App.</p>
      </li>
      <li>
        <b>INDEMNIFICATION&nbsp;</b><p>13.1 To the extent permitted by applicable law, you agree to indemnify us for any claims, actions, demands, losses, liabilities, damages, costs and expenses (including legal expenses and other costs, such as attorneys' fees) reasonably incurred by us that arise out of your use of the App in breach of these Terms. We reserve the right to assume the exclusive defense and control of any claim brought by a third party in connection with your use of the App and you agree to assist and co-operate with us in relation to any such claim.</p>
      </li>
      <li>
        <b>TERMINATION&nbsp;&nbsp;</b><p>14.1 The Unified Global Companies may discontinue, suspend or modify the App at any time without notice and may block, terminate or suspend your access to the App at any time without notice for any reason in its sole discretion, even if access continues to be allowed to others. Upon termination, you must cease use of the App and remove it from all applicable Devices.</p>
      </li>
      <li>
        <b>EVENTS OUTSIDE OUR CONTROL</b>&nbsp;<p>15.1 We will not be liable or responsible for any failure to perform, or delay in performance of, any of our obligations under these Terms that is caused by any act or event beyond our reasonable control, including any failure of public or private telecommunications networks.</p>
      </li>
      <li>
        <b>THIRD-PARTY BENEFICIARIES AND APP STORE / PLAY STORE&nbsp;</b><p>16.1 With respect to downloading the App, you agree to comply with the App Store Rules and Play Store Rules, as applicable. You acknowledge that the availability of the App may be dependent on the App Store or Play Store from which you receive the App. You acknowledge these Terms are between you and Unified Global and not with the App Store or Play Store. The App Store and Play Store are not responsible for the App, the content thereof, maintenance, support services, and warranty therefor, and addressing any claims relating thereto (e.g., product liability, legal compliance, or intellectual property infringement). You acknowledge that the App Store and Play Store (and their respective subsidiaries) are third party beneficiaries to these Terms and will have the right to enforce these Terms against you.</p>
      </li>
      <li>
        <b>LAW AND JURISDICTION&nbsp;</b><p>17.1 To the extent permitted by applicable law, these Terms are governed and construed by the laws of the state of New York, U.S.A. and you consent to the non-exclusive jurisdiction of the New York courts. We retain the right to bring proceedings against you for breach of these Terms in your country of residence or other relevant country.</p>
      </li>
      <li>
        <b>OTHER IMPORTANT TERMS</b><p>18.1 We may transfer our rights and obligations under these Terms to another organization, but this will not affect your rights or your obligations under these Terms. You may not transfer your rights or obligations under these Terms.</p>
        <p>18.2 If we fail to insist that you perform any of your obligations under these Terms, or if we do not enforce our rights against you, or if we delay in doing so, that will not mean that we have waived our rights against you and will not mean that you do not have to comply with those obligations.</p>
        <p>18.3 Each of the conditions of these Terms operates separately. If any court or competent authority decides that any of them are unlawful or unenforceable, the remaining conditions will remain in full force and effect.</p>
        <p>18.4 These Terms constitute the entire agreement between us with respect to your use of the App. Such Terms supersede all prior understandings or agreements between us. In addition, in entering into these Terms you have not relied on and (in the absence of fraud) you shall have no right or remedy in respect of any statement, representation, assurance or warranty other than as set out in these Terms.</p>
        <p>18.5 Except to the extent specified in sections 16.1 and 18.1 hereof, or otherwise permitted by applicable law, a person or entity who is not party to these Terms may not enforce these Terms.</p>
        <p>18.6 No agency, partnership, joint venture or employment relationship is created as a result of these Terms and neither of us has any authority of any kind to bind the other in any respect.</p>
      </li>
    </ol>
  </div>
</div>