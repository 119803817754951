import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser} from '@angular/common';


@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  // TODO: update app links once accepted in app stores

  // ***** Project specific settings *****

  public gcsBaseUrl = 'https://storage.googleapis.com/';

  public hashidsSalt = 'UnifiedGlobal/Lyztin@1.0';

  // ***** END *****

  apiDomain: string;
  apiBaseUrl: string;

  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) platformId: string
  ) {

    this.apiDomain = environment.apiDomain;

    console.log('API: domain set to ', this.apiDomain);

    this.apiBaseUrl = this.apiDomain + '/api/';

    if (environment.production) {
      // Disable all console.log output in browsers
      console.log('API: production environment detected - turning off console logging');
      if (isPlatformBrowser(platformId)) {
        window.console.log = () => { };
      }
      console.log('API: ***** this should not be visible *****');
    }
  }

  getCountries() {
    return new Promise(resolve => {

      const qp = {
        filter: '{"order": "name ASC"}'
      };

      this.http.get(this.apiBaseUrl + 'Countries', { params: qp }).subscribe((data: any) => {
        console.log('Got data from API: ', data);
        resolve(data);
      }, httpError => {
        resolve(httpError.error);
      });

    });
  }

  getSettingValue(name: string) {
    return new Promise(resolve => {

      const qp = {
        filter: '{ "where": { "name": "' + name + '" } }'
      };

      this.http.get(this.apiBaseUrl + 'Settings', { params: qp }).subscribe((data: any) => {
        console.log('Got data from API: ', data);
        resolve(data);
      }, httpError => {
        resolve(httpError.error);
      });

    });
  }

}
