<br>

<div *ngIf="collection" class="container jumbotron">

  <div class="card text-center">
    <img class="card-img-top" [src]="collection.imageUrl? collection.imageUrl : '../../../assets/images/logos/logo-210.png'" alt="Lyzt">
    <div class="card-body">
      <h1 class="card-title">{{ collection.name }}</h1>
      <p class="card-text">{{ collection.description }}</p>
    </div>
    <div class="card-footer text-muted">
      View this Lyzt in the Lyztin app.
    </div>
  </div>

  <br>

  <div class="row">
    <div class="col-sm-6">
      <div class="card text-center">
        <img class="card-img-top" src="../../../assets/images/graphics/apple-app-store-badge.png" alt="Apple App Store">
        <div class="card-body">
          <p class="card-text">Coming soon</p>
        </div>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="card text-center">
        <img class="card-img-top" src="../../../assets/images/graphics/google-play-badge.png" alt="Google Play Store">
        <div class="card-body">
          <p class="card-text">Coming soon</p>
        </div>
      </div>
    </div>
  </div>

</div>